@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;1,200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;1,200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;1,200&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;1,200&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #17181a; 

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

nav{
    -webkit-align-items: center;
            align-items: center;
    background-color: #1a1b1d;
    box-shadow: 0px 2px 7px black;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    min-height: 100px;
    padding: 0 20px;
    -webkit-flex-direction: row-reverse;
            flex-direction: row-reverse;
}

nav .nav-brand{
    
    font-size: 2em;
    font-weight: bold;
    font-family: 'Montserrat', 'sans-serif';
    background: red;
    background: linear-gradient(to right, #09c6f9,#045de9); 
    -webkit-background-clip: text; 
            background-clip: text;
    -webkit-text-fill-color: transparent;
}


.toolbox{
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    font-family: 'Montserrat', 'sans-serif';
}

.toolbox label{
    color: white;
    display: block;
    text-align: center;
    font-family: 'Montserrat', 'sans-serif';

}

.toolbox > div{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    font-family: 'Montserrat', 'sans-serif';

}

.toolbox button{
    background-color: #111;
    border-radius: 5px;
    border: none;
    color: #f1f1f1;
    cursor: pointer;
    font-size: 1em;
    font-weight: bold;
    margin: 0 10px;
    outline: none;
    padding: 13px 10px;
    font-family: 'Montserrat', 'sans-serif';
    transition: all 0.7s ease 0s;
}


.toolbox .randomize:disabled:hover{
    background-color: #ff6961;
}
.toolbox .randomize:disabled {
    background-color: #055975;
    color: #696969;
}
.toolbox .randomize:enabled:hover {
    background-color: #50a853;
}


/* Credits to Kocsten for the awesome button styling */
/* https://dev.to/webdeasy/top-20-css-buttons-animations-f41 */
.toolbox .glow-on-hover {
    width: 190px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.toolbox .glow-on-hover:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    -webkit-filter: blur(5px);
            filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    -webkit-animation: glowing 20s linear infinite;
            animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.toolbox .glow-on-hover:active {
    color: #000
}

.toolbox .glow-on-hover:active:after {
    background: transparent;
}

.toolbox .glow-on-hover:hover:before {
    opacity: 1;
}

.toolbox .glow-on-hover:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}
.toolbox .glow-on-hover:enabled:hover{
    color: #50a853;
}
.toolbox .glow-on-hover:disabled {
    color: #696969;
}

.toolbox .glow-on-hover:disabled:hover {
    color: #ff6961;

}

@-webkit-keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.toolbox .randomize {
    width: 190px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.toolbox .randomize:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    -webkit-filter: blur(5px);
            filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    -webkit-animation: glowing 20s linear infinite;
            animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}

.toolbox .randomize:active {
    color: #000
}

.toolbox .randomize:active:after {
    background: transparent;
}

.toolbox .randomize:hover:before {
    opacity: 1;
}

.toolbox .randomize:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

.toolbox .randomize:disabled:hover{
    color: #ff6961;
}
.toolbox .randomize:enabled:hover {
    color: #50a853;
}
@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.toolbox .selecter {
    width: 190px;
    height: 50px;
    border: none;
    outline: none;
    color: #fff;
    background: #111;
    cursor: pointer;
    position: relative;
    z-index: 0;
    border-radius: 10px;
}

.toolbox .selecter:before {
    content: '';
    background: linear-gradient(45deg, #ff0000, #ff7300, #fffb00, #48ff00, #00ffd5, #002bff, #7a00ff, #ff00c8, #ff0000);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    -webkit-filter: blur(5px);
            filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    -webkit-animation: glowing 20s linear infinite;
            animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;   
}

.toolbox .selecter:active {
    color: #000;
}

.toolbox .selecter:active:after {
    background: transparent;
}

.toolbox .selecter:hover:before {
    opacity: 1;
}

.toolbox .selecter:after {
    z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #111;
    left: 0;
    top: 0;
    border-radius: 10px;
}

.toolbox .selecter:disabled {
    color: #696969;
}

.toolbox .selecter:enabled:hover{
    color: #50a853;
}

.toolbox .selecter:disabled:hover {
    color: #ff6961;

}

@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}

.toolbox select, input[type='range']{
    cursor: pointer;
    margin: 0 10px;
}

.toolbox input, select, button{
    min-width: 150px;
}

.toolbox select {
    background: #111;
    border-radius: 5px;
    border: none;
    color: #f1f1f1;
    font-size: 0.9em;
    font-weight: bold;
    padding: 5px 5px;
    font-family: 'Montserrat', 'sans-serif';
    transition: all 0.7s ease 0s;
}

@media(max-width: 992px){
    nav{
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .toolbox{
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        text-align: center;
    }

    .toolbox > div{
        margin: 10px 0;
    }
}
.listBlocks{
    margin: 0 auto;
    min-height: 520px;
    min-width: 90%;
    text-align: center;
    
}

.listBlocks > .block{
    background-color: turquoise;
    color: #282c34;
    font-family: 'Montserrat', 'sans-serif';
    display: inline-block;
    font-size: 10px;
    font-weight: bold;
    margin: 0 2px;
    text-align: center;
    transition-duration: 150ms;
    transition-property: height;
    transition-timing-function: ease-in;
}
.legends{
    color: white;
    display: -webkit-flex;
    display: flex;
    font-size: 1.2em;
    font-family: 'Montserrat', 'sans-serif';
    -webkit-justify-content: space-between;
            justify-content: space-between;
    margin: 50px auto;
    max-width: 800px;
    padding: 0 10px;
}

.key{
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
}


.sq{
    display: inline-block;
    height: 20px;
    margin: 0 10px;
    width: 20px;
}

.compare{
    background-color: #ffff50;
}

.swap {
    background-color: red;
}

.sorted{
    background-color: #4bc52e;
}

@media(max-width: 564px){
    .legends{
        font-size: 0.8em;
        text-align: center;
    }
}
.SortInfo {
  text-align: left;
  width: 80%;
  max-width: 1200px;
  padding: 1rem;
  margin: 0 auto;
}

.SortInfo h1 {
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  font-family: 'Montserrat', 'sans-serif';
  background: red;
  background: linear-gradient(to right, #09c6f9,#045de9); 
  -webkit-background-clip: text; 
          background-clip: text;
  -webkit-text-fill-color: transparent;

}

.SortInfo hr {
  background: -webkit-linear-gradient(left, #09c6f9,#045de9);
  height: 5px;
  border: none;
}
.SortInfo_Body {
  display: -webkit-flex;
  display: flex;
  
}

.SortInfo_Body > div {
  padding: 20px;
  -webkit-flex: 1 1;
          flex: 1 1;
}

.SortInfo_Body .SortInfo__Article{
  /* padding-right: 100px; */
  margin-right: 20px;
  /* border-right: 1px solid #61dbfb; */
  text-align: center;
  background-color: #222233;
  color: #AACCFF;
  box-shadow: 0px 2px 7px black;
}

.SortInfo_Body .SortInfo__Aside{
  text-align: center;
  background-color: #222233;
  color: #AACCFF;
  margin-left: 20px;
  box-shadow: 0px 2px 7px black;
  /* padding-left: 20px; */
}


@media (max-width: 992px) {
  .SortInfo_Body {
    -webkit-flex-wrap : wrap;
            flex-wrap : wrap;
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .SortInfo_Body .SortInfo__Article {
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .SortInfo_Body .SortInfo__Aside {
    margin-left: 0px;
    margin-top: 20px;
  }
}
